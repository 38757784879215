<template>
  <b-container fluid>
    <ValidationObserver ref="customerWeightObserver" v-slot="{ invalid }">
      <form>
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-6" label-for="date" :label="$t('Date')">
                <date-picker v-model="model.date"
                             :tag-name="'date'"
                             :is-required="true"
                             :max-date-today="true" />
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('Seance')" label-for="seance">
                <ValidationProvider :name="$t('Seance')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.seance" type="number" min="1"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-12"
                            label-for="dietType"
                            :label="$t('DietType')">
                <b-form-input v-model="model.dietType" />
              </b-form-group>
            </b-row>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                <div class="ribbon ribbon--clip ribbon--left ribbon--primary">
                  <div class="ribbon__target">
                    <span class="ribbon__inner" /> {{ $t("Details") }}
                  </div>
                </div>
                <br />
                <br />
              </b-col>
            </b-row>

            <b-row>
              <b-form-group class="col-md-6" :label="$t('BodyWeight')" label-for="bodyWeight">
                <ValidationProvider :name="$t('BodyWeight')" rules="required" v-slot="{ errors }">
                  <b-form-input :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.bodyWeight" type="text"
                                @input="setDecimalFormat('bodyWeight',$event)"
                                @focusout="calculations()" @keypress="$root.isNumber"></b-form-input>
                  <b-form-invalid-feedback> {{ $t('ValidationMessage.CannotBeEmpty') }} </b-form-invalid-feedback>
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="col-md-6" :label="$t('Muscle')" label-for="muscle">
                <b-form-input v-model="model.muscle"
                              type="text"
                              @input="setDecimalFormat('muscle',$event)"
                              @keypress="$root.isNumber"></b-form-input>
              </b-form-group>

              <b-form-group class="col-md-6" label-for="bmi" :label="$t('BMI')">
                <b-form-input v-model="model.bmi"
                              type="text"
                              disabled @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-6" label-for="bmr" :label="$t('BMR')">
                <b-form-input v-model="model.bmr"
                              type="text"
                              disabled @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-4" label-for="fat" :label="$t('Fat')">
                <b-form-input v-model="model.fat"
                              type="text"
                              @input="setDecimalFormat('fat',$event)"
                              @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-4" label-for="fatRate" :label="$t('FatRate')">
                <b-form-input v-model="model.fatRate"
                              type="text"
                              @input="setDecimalFormat('fatRate',$event)"
                              @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-4" label-for="organFat" :label="$t('OrganFat')">
                <b-form-input v-model="model.organFat"
                              type="text"
                              @input="setDecimalFormat('organFat',$event)"
                              @keypress="$root.isNumber" />
              </b-form-group>
            </b-row>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                <div class="ribbon ribbon--clip ribbon--left ribbon--primary">
                  <div class="ribbon__target">
                    <span class="ribbon__inner" /> {{ $t("BodyMeasurements") }}
                  </div>
                </div>
                <br />
                <br />
              </b-col>
            </b-row>

            <b-row>
              <b-form-group class="col-md-6" label-for="waistCircumference" :label="$t('WaistCircumference')">
                <b-form-input v-model="model.waistCircumference"
                              type="text"
                              @input="setDecimalFormat('waistCircumference',$event)"
                              @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-6" label-for="hipCircumference" :label="$t('HipCircumference')">
                <b-form-input v-model="model.hipCircumference"
                              type="text"
                              @input="setDecimalFormat('hipCircumference',$event)"
                              @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-6" label-for="rightArm" :label="$t('RightArm')">
                <b-form-input v-model="model.rightArm"
                              type="text"
                              @input="setDecimalFormat('rightArm',$event)"
                              @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-6" label-for="leftArm" :label="$t('LeftArm')">
                <b-form-input v-model="model.leftArm"
                              type="text"
                              @input="setDecimalFormat('leftArm',$event)"
                              @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-6" label-for="rightLeg" :label="$t('RightLeg')">
                <b-form-input v-model="model.rightLeg"
                              type="text"
                              @input="setDecimalFormat('rightLeg',$event)"
                              @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-6" label-for="leftLeg" :label="$t('LeftLeg')">
                <b-form-input v-model="model.leftLeg"
                              type="text"
                              @input="setDecimalFormat('leftLeg',$event)"
                              @keypress="$root.isNumber" />
              </b-form-group>

              <b-form-group class="col-md-12 form-group" label-for="notes" :label="$t('Notes')">
                <b-form-textarea v-model="model.notes"
                                 rows="2" />
              </b-form-group>
            </b-row>
          </template>
        </iq-card>

        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitWeightTracking()"></modal-footer-button>
      </form>
    </ValidationObserver>
  </b-container>
</template>

<script>
  import DatePicker from '../../../components/shared/DatePicker'
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'
  import { refreshWeightTracking } from '../../../helpers/dataRefresher'
  import customerWeightService from '../../../services/customerWeight'
  export default {
    name: 'NewWeightTracking',
    components: {
      ModalFooterButton,
      DatePicker,
    },
    props: {
      customerId: String,
      weightTrackingId: String
    },
    data() {
      return {
        customer: {
        },
        model: {
          id: '',
          customerID: '',
          date: new Date().toLocaleDateString().substr(0, 10),
          seance: null,
          dietType: null,
          bodyWeight: null,
          bmi: null,
          bmr: null,
          muscle: null,
          fat: null,
          fatRate: null,
          organFat: null,
          waistCircumference: null,
          hipCircumference: null,
          rightArm: null,
          leftArm: null,
          rightLeg: null,
          leftLeg: null,
          notes: null
        },
        isSubmitting: false
      };
    },
    methods: {
      getCustomerInformation() {
        customerWeightService.getCustomerInformation(this.model.customerID).then((response) => {
          if (response) {
            this.customer = response;
          }
        })
      },
      getWeightTracking() {
        customerWeightService.getWeightTracking(this.model.id).then((response) => {
          if (response) {
            this.model = response;
          }
        })
      },
      getLastSeanceNumber() {
        customerWeightService.getLastSeanceNumber(this.model.customerID).then(
          (response) => {
            this.model.seance = response + 1;
          })
      },
      async submitWeightTracking() {
        const isValid = await this.$refs.customerWeightObserver.validate();

        if (!isValid) {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
          return;
        }
        this.isSubmitting = true;

        customerWeightService.submitWeightTracking(this.model).then((response) => {
          this.isSubmitting = false;
          if (response) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t("Success"));
          } else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => {
          this.isSubmitting = false;
          refreshWeightTracking();
        });
      },
      calculateBmi() {
        if (this.model.bodyWeight != null && this.model.bodyWeight > 0 && this.customer.height != null && this.customer.height > 0) {
          let bmiCalculationResult = 0;
          let heightM2 = (this.customer.height / 100) * (this.customer.height / 100);
          bmiCalculationResult = (this.model.bodyWeight / heightM2);
          this.model.bmi = Number((bmiCalculationResult).toFixed(2));
        }
      },
      calculateBmr() {
        if (this.customer.gender != null && this.customer.age != null && this.model.bodyWeight != null && this.model.bodyWeight > 0 && this.customer.height != null && this.customer.height > 0 && this.customer.activityLevel != null) {
          let bmrCalculationResult = 0;
          if (this.customer.gender == 1)// 1 - female
            bmrCalculationResult = 655 + (9.563 * this.model.bodyWeight) + (1.850 * this.customer.height) - (4.676 * this.customer.age);
          else if (this.customer.gender == 2)// 2 - male
            bmrCalculationResult = 66.5 + (13.76 * this.model.bodyWeight) + (5.003 * this.customer.height) - (6.755 * this.customer.age);
          if (this.customer.activityLevel == 1)
            bmrCalculationResult = bmrCalculationResult * 1.2;
          else if (this.customer.activityLevel == 2)
            bmrCalculationResult = bmrCalculationResult * 1.375;
          else if (this.customer.activityLevel == 3)
            bmrCalculationResult = bmrCalculationResult * 1.55;
          else if (this.customer.activityLevel == 4)
            bmrCalculationResult = bmrCalculationResult * 1.725;
          else
            bmrCalculationResult = bmrCalculationResult * 1.9;
          this.model.bmr = Number((bmrCalculationResult).toFixed(2));
        }
      },
      calculations() {
        this.calculateBmi();
        this.calculateBmr();
      },
      setDecimalFormat(fieldName, value) {
        this.model[fieldName] = value.replace(',', '.');
      }
    },
    watch: {
      'model.bodyWeight': function () {
        if (this.model.bodyWeight != null && this.model.bodyWeight > 0 && this.weightTrackingId)
          this.calculations();
      }
    },
    mounted: function () {
      this.model.customerID = this.customerId;
      this.model.id = this.weightTrackingId;

      this.getCustomerInformation();

      if (this.model.id && this.model.id.length > 0)
        this.getWeightTracking();
      else
        this.getLastSeanceNumber();

    },
  }
</script>
